














import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({})
export default class PaymentValidation extends Vue {
  private validated = false;
  private error: null | string | any = null;
  created() {
    const { email, pi } = this.$route.query;
    if (!email) this.error = 'email_missing';
    if (!pi) this.error = 'pi_missing';
    axios
      .post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/stripe/confirmPayment`, {
        email,
        pi,
        provider: process.env.VUE_APP_BUILD
      })
      .then(() => {
        this.validated = true;
      })
      .catch(e => {
        this.error = this.$t('Le paiement est invalide ou a déjà été validé.');
      });
  }
}
